.App_AutoUpdate {
    text-align: center  ;
    color: cornflowerblue;
    margin-top: 40px;
}

.App-logo_AutoUpdate {
    height: 40vmin;
}

.App-header_AutoUpdate {
    background-color: yellowgreen;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: greenyellow;
}
#data_AutoUpdate{
    width: 150px!important;
    border-bottom: none !important;
}

#text_AutoUpdate{
    width: 100%;

}

label{
    color: chartreuse;
}
input{
    color: blue;
}


.App-link_AutoUpdate {
    color: #09d3ac;
}

.red_animation_AutoUpdate{
    animation: background-red 2s ease!important;
}

@keyframes background-red {
    0% {
        background: rgba(	255, 115, 115, 0.2);
        animation-timing-function: ease-out;
    }
    25% {
        background: rgba(	255, 115, 115, 0.6);
        animation-timing-function: ease-in;
    }
    50% {
        background: rgba(	255, 115, 115, 0.4);
        animation-timing-function: ease-out;
    }
    75% {
        background: rgba(	255, 115, 115, 0.7);
        animation-timing-function: ease-in;
    }
    100% {
        background: rgba(	255, 115, 115, 0.2);
    }
}
.Button_Auto_Update {
    margin-top: 40px;
    display:flex;
    justify-content: center;

}
.forma_Update_Auto{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.forma_Update_Auto>div{
    width:50%!important;
}
