.App_AddUser {
    text-align: center  ;
    color: black;
    font-family: "Times New Roman";
    font-size: 20px;
    font-weight: normal;
    margin-top: 40px;
}

#text_AddUser{
    width: 100%;
    color:rgba(255,0,0,0.8)!important;
}

.pole_textfield label_AddUser{
    color : red!important;
}

label_AddUser{
    color: chartreuse;
}
.role_select_AddUser{
    display: flex;
}

input_AddUser{
    color: blue;
}


.App-link_AddUser {
    color: #09d3ac;
}

.red_animation_AddUser{
    animation: background-red 2s ease!important;
}


.form_AddUser>div{
    width:50%!important;
}