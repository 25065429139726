.notBorderBottom {
    border-bottom: none!important;
    margin-top: 50px!important;
    border-top: black 2px solid;
}
.maxHeight {
    height: auto !important;
}
.miniPaddingAllUsers {
    padding: 6px 14px 6px 6px!important;

}
.headBoldAllUsers{
    padding: 6px 14px 6px 6px!important;
    font-family: Times New Roman !important;
    font-weight: bold!important;
    font-size: 19px!important;
    border-bottom: black 2px solid!important;
}
.headCheckBoxBorderAllUsers{
    border-bottom: black 2px solid!important;
    font-weight: bold!important;
}
.fatText {
    font-weight: 900!important;
}