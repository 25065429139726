.App_Find_Date1{
    position:center;
    right: calc(50vw - 80px)!important;
    text-align: center  ;
    color: black;
    font-family: "Times New Roman";
    font-size: 20px;
    font-weight: lighter!important;
    margin-top: 20px;
}
.notFind_Date1{
    color: black;
    font-weight: bold;
    font:lighter normal 30px "Times New Roman";
    margin-top: 150px;

}
.indent_Find_Date1{
    margin-top: 30px;
    width: 98%;
}

.form_Find_Date1>div{
    width:50%!important;
}

.table_center_Find_Date1{
    display:flex;
    justify-content: center;

}
