.App_Find_All_Users {
    text-align: center  ;
    color: black;
    font-family: "Times New Roman";
    font-size: 20px;
    font-weight: normal;
    margin-top: 40px;
}

.inline_Find_All_Users {
    display: flex;
    justify-content: center;
    align-items: center;
}
.indent_Find_AllUsers{
    margin-top: 30px;
    width: 98%;
}
.table_center_Find_AllUsers {
    display:flex;
    justify-content: center;

}