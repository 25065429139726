.App_Find_Line_Date {
    text-align: center  ;
    color: black;
    font-family: "Times New Roman";
    font-size: 20px;
    font-weight: normal;
    margin-top: 40px;
}

.Betwen_Find_Line_Date{
    margin-left: 50px!important;
}

.red_animation_Find_Line_Date{
    animation: background-red 2s ease!important;
}
.indent_Find_Line_Date{
    margin-top: 30px;
    width: 98%;
}
.notFind_Find_Line_Date{
    color: black;
    font-weight: bold;
    font:lighter normal 30px "Times New Roman";
    margin-top: 150px;

}

.form_Find_Line_Date>div{
    width:50%!important;
}
.table_center_Find_Line_Date{
    display:flex;
    justify-content: center;

}
