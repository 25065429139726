.title{
    font-size: 40px!important;
    color:white!important;
}

.navTop{
    display: flex;
    justify-content: space-between;
}

a {
    text-decoration: none;
    color: inherit;
}

@media (min-width:1000px) {


    .center {
        display: flex;
        width: 200px;
        left: calc(50vw - 100px);
        position: absolute;
        justify-content: center;
    }

}

@media (max-width:999px) {


    .center {
        display: flex;
        justify-content: center;
        width: 160px;
        left: calc(50vw - 80px);
        position: absolute;
    }

}
.lableAccountRight{
    margin-top:0px!important;
    margin-bottom: 0px!important;
    margin-right: 0px!important;
}
