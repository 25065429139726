.notBorderBottom {
    border-bottom: none!important;
    margin-top: 50px!important;
    border-top: black 2px solid!important;
}
.maxHeight {
    height: auto !important;
}
.miniPadding {
    padding: 6px 14px 6px 6px!important;
}

.listAuto_div_small div {
    width: 130px;
}
.headCheckBoxBorder{
    border-bottom: black 2px solid!important;
    font-weight: bold!important;
}
.headBold{
    min-width:20px!important;
    padding: 6px 6px 6px 6px!important;
    font-family: Times New Roman !important;
    font-weight: bold!important;
    font-size: 19px!important;
    border-bottom: black 2px solid!important;
}

.fatText {
    font-weight: 900!important;
}