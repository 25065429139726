

.App_Find_General {

    text-align: center  ;
    color: black;
    font-family: "Times New Roman";
    font-size: 20px;
    font-weight: normal;
    margin-top: 40px;
}

.App_Find_General_Text_Field {
    margin: 15px!important;
}

.Button_Find_General {
    height: 40px;
    align-self: flex-end;
}
.red_animation_Find_General{
    animation: background-red 2s ease!important;
}
.indent_Find_General{
    margin-top: 30px;
    width: 98%;
}
.notFind_Find_General{
    color: black;
    font-weight: bold;
    font:lighter normal 30px "Times New Roman";
    margin-top: 150px;

}

.form_Find_General>div{
    width:50%!important;
}
.table_center_Find_General{
    display:flex;
    justify-content: center;

}