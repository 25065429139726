.App_Find_Number {
    position:static;
    text-align: center  ;
    right: calc(50vw - 0px)!important;
    color: black;
    font-family: "Times New Roman";
    font-size: 20px;
    font-weight: normal!important;
    margin-top: 40px;
}

h2 {
    font-weight: normal;
}

.indent_Find_Number{
    margin-top: 30px;
    width: 98%;

}
.notFind_Find_Number{
    color: black;
    font-weight: bold;
    font:lighter normal 30px "Times New Roman";
    margin-top: 150px;

}
.form_Find_Number>div{
    width:50%!important;

}

.table_center_Find_Number{
    display:flex;
    justify-content: center;

}