.App_User_Update {
    position:static;
    text-align: center  ;
    right: calc(50vw - 0px)!important;
    color: black;
    font-family: "Times New Roman";
    font-size: 20px;
    font-weight: normal!important;
    margin-top: 40px;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: yellowgreen;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: greenyellow;
}
#data{
    width: 150px!important;
    border-bottom: none !important;
}

#text_User_Update{
    width: 100%;
    color:rgba(255,0,0,0.8)!important;
}

.pole_text_User_Update label{
    color : red!important;
}


input{
    color: blue;
}

.red_animation_User_Update{
    animation: background-red 2s ease!important;
}

.form_User_Update>div{
    width:50%!important;
}
